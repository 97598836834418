<template>
  <mds-row>
    <mds-col>
      <mds-table row-hover>
        <mds-thead>
          <mds-th
            v-for="header in $options.headers"
            :key="header.fieldName"
            :style="header.style"
          >
            {{ header.text }}
          </mds-th>
        </mds-thead>
        <mds-tbody>
          <template v-for="tenant in tenants">
            <mds-tr
              :key="tenant.id"
            >
              <mds-td
                v-for="header in $options.headers"
                :key="header.fieldName"
                :style="header.style"
              >
                <template>
                  {{ tenant[header.fieldName] || '—' }}
                </template>
              </mds-td>
            </mds-tr>
          </template>
        </mds-tbody>
      </mds-table>
    </mds-col>
  </mds-row>
</template>

<script>
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { TABLE_HEADERS } from '@/constants/tenants.constant';
import { getTenants } from '@/services/api/tenant.service';

export default {
  name: 'UsersTable',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
  },
  data () {
    return {
      tenants: [],
    };
  },
  created () {
    this.getTenants();
  },
  methods: {
    async getTenants () {
      const { data: tenants } = await getTenants();
      this.tenants = tenants;
    },
  },
  headers: TABLE_HEADERS,
};
</script>

<style lang="scss" scoped>
.users {
  &__tenant {
    &_tag {
      margin-right: $mds-space-1-and-a-half-x;
    }
  }
}
</style>

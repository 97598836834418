<template>
  <mds-layout-grid>
    <tenants-table />
  </mds-layout-grid>
</template>

<script>
import TenantsTable from '@/components/user-management/tenants/TenantsTable.vue';

export default {
  name: 'TenantsListPage',
  components: {
    TenantsTable,
  },
};
</script>

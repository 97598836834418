export const TABLE_HEADERS = [
  {
    fieldName: 'id',
    text: 'ID',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'name',
    text: 'Tenant Name',
    style: {
      width: '20%',
    },
  },
  {
    fieldName: 'code',
    text: 'Tenant Code',
    style: {
      width: '20%',
    },
  }
];
